
import { createContext, useState } from 'react';

export const stateContext = createContext();

const StateProvider = (props) => {

    const [state, setState] = useState({
        help: false,
        activeMenu: false
    });

    return (
        <stateContext.Provider value={ [state, setState] }>
            {props.children}
        </stateContext.Provider>
    ); 
}

export default StateProvider;
