import React from 'react';
import { createRoot } from 'react-dom/client';
import ReactDOM from 'react-dom';

import './i18n';

import App from './App-iacf';

import { Provider } from 'react-redux';
import { configureStore } from './redux/store';

// ReactDOM.render(
//     <Provider store={configureStore({})}>
//         <App />
//     </Provider>,
//     document.getElementById('root')
// );

const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(
    <Provider store={configureStore({})}>
        <App />
    </Provider>
);
