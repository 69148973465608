// @flow
import React, { useEffect, useState, useContext } from 'react';
import { Outlet } from 'react-router-dom';
// import config from '../config.js';
import { customAPI } from '../helpers-iacf/api';
import { useDispatch } from 'react-redux';
import { Container } from 'react-bootstrap';

// actions

// constants

// components
import Loader from './../components-iacf/IACFLoader.js';
import ErrorScreen from './../layouts-iacf/ErrorScreen.js';

// helpers

// Global contexts
import {  currentCompanyContext } from './../helpers-iacf/currentCompanyProvider.js';
import { currentUserContext } from './../helpers-iacf/currentUserProvider.js';



const loading = () => <div className=""></div>;



const PrintLayout = ({ children }, state) => {

    const dispatch = useDispatch();

    // Global states
    const [ currentCompany, setCurrentCompany ] = useContext( currentCompanyContext );
    const [ currentUser, setCurrentUser ] = useContext( currentUserContext );

    // General state prop handlers:
    const [error, setError] = useState(null);
    const [isLoaded, setMenuIsLoaded] = useState(false);
    const [menuItems, setMenuItems] = useState([]);

    const [userIsLoaded, setUserIsLoaded] = useState(false);
    
    const [companyIsLoaded, setCompanyIsLoaded] = useState(false);



    useEffect(() => {

        if (userIsLoaded){
            return () => {};
        }

        if (!currentUser || !currentUser.hash){
            return () => {};
        }

        customAPI.get( 'staff/me', currentUser, (result) => {

            setUserIsLoaded(true);
            setCurrentUser(result);

        }, (error) => {
            setUserIsLoaded(true);
            setError(error);
        });

    }, [userIsLoaded, currentUser]);

    useEffect(() => {

        if (companyIsLoaded){
            return () => {};
        }

        if (!currentUser || !currentUser.hash){
            return () => {};
        }

        customAPI.get( 'companies/current', currentUser, (result) => {

            setCompanyIsLoaded(true);

            document.title = result.name + ' System';
            
            setCurrentCompany(result);

            setMenuItems(result.menu);

        }, (error) => {
            setCompanyIsLoaded(true);
            setError(error);
        });        
        
    }, [companyIsLoaded, currentUser]);



    if (error) {
        return (
            <>
                <ErrorScreen error={error} />
            </>
        );
    } else if (!userIsLoaded && !companyIsLoaded) {
        return (
            <>
                <Loader />
            </>
        );
    } else if (!currentUser || !currentUser.hash){
        return (
            <>
                <ErrorScreen error={'You must be logged in to view this screen.'} />
            </>
        );
    } else {
        return (
            <div className="wrapper">
                <div className="container-fluid">
                    <Outlet  />
                </div>
            </div>
        );
    }
};

export default PrintLayout;
