import { BrowserRouter } from 'react-router-dom';
import { AllRoutes } from './index';

import CurrentCompanyProvider from './../helpers-iacf/currentCompanyProvider.js';
import CurrentUserProvider from './../helpers-iacf/currentUserProvider.js';
import StateProvider from '../helpers-iacf/stateProvider.js';


const Routes = () => {
    return (
        <BrowserRouter>
            <CurrentCompanyProvider>
                <CurrentUserProvider>
                    <StateProvider>
                        <AllRoutes />
                    </StateProvider>
                </CurrentUserProvider>
            </CurrentCompanyProvider>
        </BrowserRouter> 
    );
};

export default Routes;
