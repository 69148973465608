
import React, { useContext, useState, useEffect } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
// import config from '../config.js';
import { customAPI } from '../helpers-iacf/api';
// import { APICore } from '../helpers/api/apiCore';


// Components
import Loader from '../components-iacf/IACFLoader';
import { useCookies } from 'react-cookie';



// Global contexts
import { currentUserContext } from '../helpers-iacf/currentUserProvider.js';



type PrivateRouteProps = {
    component: React.ComponentType,
    roles?: string,
};






/**
 * Private Route forces the authorization before the route can be accessed
 * @param {*} param0
 * @returns
 */
const PrivateRoute = ({ component: RouteComponent, roles, ...rest } : PrivateRouteProps) => {

    let location = useLocation();

    // Get context objects. Equivelent to super.props
    const [currentUser, setCurrentUser] = useContext(currentUserContext);    
    const [routeToLogin, setRouteToLogin] = useState(true);
    const [isLoaded, setIsLoaded] = useState(false);
    const [cookies] = useCookies(['crm_token']);

    // console.info('cookies: ', cookies);

    useEffect(() => {

        if (currentUser && currentUser.hash){
            setRouteToLogin(false);
            setIsLoaded(true); 
            return () => {};
        }

        if (cookies['crm_token']){
    
            // User is potentially logged in, attempt refresh
            console.info('Token cookie found: ', cookies);

            setCurrentUser({
                hash: cookies['crm_token']
            });

            setRouteToLogin(false);
            
        } else {

            console.info('Token cookie not found: ', cookies);
            setRouteToLogin(true);

        }

        setIsLoaded(true);

    }, [cookies, currentUser, setCurrentUser]);
    

    if (!isLoaded){

        return <Loader />

    } else if (routeToLogin){

        return <Navigate to={'/account/login'} state={{ from: location }} replace />

    } else {

        return <RouteComponent />;

    }

};

export default PrivateRoute;
