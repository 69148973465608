
import config from '../config.js';
import download from 'downloadjs';



export const customAPI = {}


customAPI.maybeJSON = async response => {
    const text = await response.text()
    try{
        const json = JSON.parse(text)
        return json
    } catch(err) {
        throw new Error("A badly formatted response was recevied from the server (not in the expected format): " + text);
    }
}


customAPI.get = ( path, currentUser, successFn, failureFn, debugMode = true ) => {

    if (!path){
        return failureFn('No API path supplied.');
    }

    console.info('API DEBUG (' + path + ', get): sending...');

    fetch(config.API_URL + path, {  
        method: 'GET', 
        mode: "cors",
        headers: {
            'Authorization': `Bearer: ` + currentUser.hash,
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        }
    })
        .then(customAPI.maybeJSON)
        .then(
            (result) => {
                if (debugMode){
                    console.info('API DEBUG (' + path + ', get): Response received: ', result);
                }                
                if (typeof result.error !== "undefined"){
                    failureFn(result.error);
                } else {
                    successFn(result.result);
                }
            },
            (error) => {
                if (debugMode){
                    console.info('API DEBUG (' + path + ', get): Error in request: ', error);
                }
                failureFn(error);
            }
        );
}



customAPI.post = ( path, data, currentUser, successFn, failureFn, debugMode = true ) => {

    if (!path){
        return failureFn('No API path supplied.');
    }

    if (!data){
        return failureFn('No data supplied.');
    }

    if (config.DEBUG_MODE){
        if (path.indexOf('?') > 0){
            path += '&XDEBUG_TRIGGER=true';
        } else {
            path += '?XDEBUG_TRIGGER=true';
        }
    }

    fetch(config.API_URL + path, {  
        method: 'POST', 
        body: JSON.stringify(data), 
        mode: "cors",
        headers: {
            'Authorization': `Bearer: ` + currentUser.hash,
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        }
    })
        .then(res => res.json())
        .then(
            (result) => {
                if (config.DEBUG_MODE){
                    console.info('API DEBUG (' + path + ', post): Response received: ', result);
                }
                if (typeof result.error !== "undefined"){
                    failureFn(result.error);
                } else {
                    successFn(result.result);
                }
            },
            (error) => {
                if (config.DEBUG_MODE){
                    console.info('API DEBUG (' + path + ', post): Error in request: ', error);
                }
                failureFn(error);
            }
        );
 
}



customAPI.postFile = ( path, data, currentUser, successFn, failureFn, debugMode = true ) => {

    if (!path){
        return failureFn('No API path supplied.');
    }

    if (!data){
        return failureFn('No data supplied.');
    }

    if (config.DEBUG_MODE){
        if (path.indexOf('?') > 0){
            path += '&XDEBUG_TRIGGER=true';
        } else {
            path += '?XDEBUG_TRIGGER=true';
        }
    }

    fetch(config.API_URL + path, {  
        method: 'POST', 
        body: data, 
        mode: "cors",
        headers: {
            'Authorization': `Bearer: ` + currentUser.hash,
            // 'Content-Type': 'application/json',
            'Accept': 'application/json'
        }
    })
        .then(customAPI.maybeJSON)
        .then(
            (result) => {
                if (config.DEBUG_MODE){
                    console.info('API DEBUG (' + path + ', post): Response received: ', result);
                }
                if (typeof result.error !== "undefined"){
                    failureFn(result.error);
                } else {
                    successFn(result.result);
                }
            },
            (error) => {
                if (config.DEBUG_MODE){
                    console.info('API DEBUG (' + path + ', post): Error in request: ', error);
                }
                failureFn(error);
            }
        );
 
}



customAPI.patch = ( path, data, currentUser, successFn, failureFn, debugMode = true ) => {

    if (!path){
        return failureFn('No API path supplied.');
    }

    if (!data){
        return failureFn('No data supplied.');
    }

    fetch(config.API_URL + path, {  
        method: 'PATCH', 
        body: JSON.stringify(data), 
        mode: "cors",
        headers: {
            'Authorization': `Bearer: ` + currentUser.hash,
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        }
    })
        .then(customAPI.maybeJSON)
        .then(
            (result) => {
                if (debugMode){
                    console.info('API DEBUG (' + path + ', patch): Response received: ', result);
                }
                if (typeof result.error !== "undefined"){
                    failureFn(result.error);
                } else {
                    successFn(result.result);
                }
            },
            (error) => {
                if (debugMode){
                    console.info('API DEBUG (' + path + ', patch): Error in request: ', error);
                }
                failureFn(error);
            }
        );

}



customAPI.delete = ( path, currentUser, successFn, failureFn, debugMode = true) => {

    if (!path){
        return failureFn('No API path supplied.');
    }

    fetch(config.API_URL + path, {  
        method: 'DELETE', 
        mode: "cors",
        headers: {
            'Authorization': `Bearer: ` + currentUser.hash,
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        }
    })
        .then(customAPI.maybeJSON)
        .then(
            (result) => {
                if (debugMode){
                    console.info('API DEBUG (' + path + ', delete): Response received: ', result);
                }
                if (typeof result.error !== "undefined"){
                    failureFn(result.error);
                } else {
                    successFn(result.result);
                }
            },
            (error) => {
                if (debugMode){
                    console.info('API DEBUG (' + path + ', delete): Error in request: ', error);
                }
                failureFn(error);
            }
        );
}

customAPI.getDownload = ( path, currentUser, successFn, failureFn, debugMode = true ) => {
    customAPI.handleDownload( 'get', path, null, currentUser, successFn, failureFn, debugMode );
}

customAPI.postDownload = ( path, data, currentUser, successFn, failureFn, debugMode = true ) => {
    customAPI.handleDownload( 'post', path, data, currentUser, successFn, failureFn, debugMode );
}





customAPI.maybeBlob = async response => {

    console.info('response.headers', response.headers);

    const contentType = response.headers.get("content-type");

    if (contentType && contentType.indexOf("application/json") !== -1) {

        const text = await response.text()
        try{

            console.info('Response text', text.trim());
            const json = JSON.parse(text.trim());
            throw new Error(json.error);

        } catch(err) {

            throw new Error( err );

        }

    } else {

        return response.blob();

    }

}

customAPI.handleDownload = ( method, path, data, currentUser, successFn, failureFn, debugMode = true ) => {

    if (!path){
        return failureFn('No API path supplied.');
    }

    fetch(config.API_URL + path + '?XDEBUG_TRIGGER=true', {  
        method: method, 
        mode: "cors",
        body: (data ? JSON.stringify(data) : null),
        headers: {
            'Authorization': `Bearer: ` + currentUser.hash
        }
    })
        .then(customAPI.maybeBlob)
        .then(
            (result) => {
                
                successFn({
                    success: true
                });
                download( result, data.filename, data.mimetype );
                            
            },
            (error) => {
                if (debugMode){
                    console.info('API DEBUG (' + path + ', get): Error in request: ', error);
                }
                failureFn(error);
            }
        );
}

