// @flow
import React from 'react';

/**
 * Renders the preloader
 */
const IACFLoader = ({ children }) => {
    return (
        <div className="custom-loader  position-relative  p-3">
            <div className="preloader">
                <div className="status" id="status">
                    <div className="bouncing-loader">
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                    {children ? (
                        children.map( (child, index) => {
                            return (
                                <div key="index">
                                    {child}
                                </div>
                            )
                        })
                    ) : (
                        <></>
                    )}
                </div>
            </div>

        </div>
    );
};

export default IACFLoader;
