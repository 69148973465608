// @flow
import React, { Suspense, useCallback, useEffect, useState } from 'react';

type ErrorScreenProps = {
    error: any,
};

const ErrorScreen = ({ error }: ErrorScreenProps ): React$Element<any> => {

    return (
        <>
            <div className="wrapper">

                <div className="content-page">
                    <div className="content">
                        
                        <h1>An error occured trying to load the page</h1>

                        <h2>Error:</h2>
                        <p>{error.message}</p>

                        <p>&nbsp;</p>
                        <p><a href="/account/logout" className="btn btn-primary">Logout</a></p>

                    </div>
                </div>

            </div>
        </>
    );
    
};

export default ErrorScreen;
