// @flow
import React, { Suspense, useCallback, useEffect, useState, useContext } from 'react';
import { Outlet, Navigate } from 'react-router-dom';
import config from '../config.js';
import { customAPI } from '../helpers-iacf/api';
import { useSelector, useDispatch } from 'react-redux';
import { Container } from 'react-bootstrap';

// actions
import { changeSidebarType } from '../redux/actions';

// constants
import * as layoutConstants from '../constants/layout';

// components
// import ThemeCustomizer from './../components-iacf/ThemeCustomizer';
// import ToDo from './../components-iacf/ToDo.js';
import Loader from './../components-iacf/IACFLoader.js';
import ErrorScreen from './../layouts-iacf/ErrorScreen.js';

// helpers


// Global contexts
import {  currentCompanyContext } from './../helpers-iacf/currentCompanyProvider.js';
import { currentUserContext } from './../helpers-iacf/currentUserProvider.js';


// utils
import { changeBodyAttribute } from '../utils';
// import { getMenuItems } from '../helpers/menu.js';
import axios from 'axios';
//import { setDatasets } from 'react-chartjs-2/dist/utils';



// code splitting and lazy loading
// https://blog.logrocket.com/lazy-loading-components-in-react-16-6-6cea535c0b52
const Topbar = React.lazy(() => import('./Topbar'));
const LeftSidebar = React.lazy(() => import('./LeftSidebar'));
const Footer = React.lazy(() => import('../layouts-iacf/Footer'));
// const RightSidebar = React.lazy(() => import('../layouts-iacf/RightSidebar'));




const loading = () => <div className=""></div>;



// Signed-in user context

const VerticalLayout = ({ children }, state) => {

    const dispatch = useDispatch();

    // Global states
    const [ currentCompany, setCurrentCompany ] = useContext( currentCompanyContext );
    const [ currentUser, setCurrentUser ] = useContext( currentUserContext );

    // General state prop handlers:
    const [error, setError] = useState(null);
    const [isLoaded, setMenuIsLoaded] = useState(false);
    const [menuItems, setMenuItems] = useState([]);

    const [userIsLoaded, setUserIsLoaded] = useState(false);
    

    const [companyIsLoaded, setCompanyIsLoaded] = useState(false);





    
    const { layoutColor, leftSideBarTheme, leftSideBarType, layoutWidth } = useSelector((state) => ({
        layoutColor: state.Layout.layoutColor,
        layoutWidth: state.Layout.layoutWidth,
        leftSideBarTheme: state.Layout.leftSideBarTheme,
        leftSideBarType: state.Layout.leftSideBarType
    }));

    const [ isMenuOpened, setIsMenuOpened ] = useState(false);


    /*
     * layout defaults
     */
    useEffect(() => {
        changeBodyAttribute('data-layout', layoutConstants.LAYOUT_VERTICAL);
    }, []);

    useEffect(() => {
        changeBodyAttribute('data-layout-color', layoutColor);
    }, [layoutColor]);

    useEffect(() => {
        changeBodyAttribute('data-layout-mode', layoutWidth);
    }, [layoutWidth]);

    useEffect(() => {
        changeBodyAttribute('data-leftbar-theme', leftSideBarTheme);
    }, [leftSideBarTheme]);

    useEffect(() => {
        changeBodyAttribute('data-leftbar-compact-mode', leftSideBarType);
    }, [leftSideBarType]);



    // Load key App Props, Menu, Current User, then show UI 


    useEffect(() => {

        if (userIsLoaded){
            return () => {};
        }

        if (!currentUser || !currentUser.hash){
            return () => {};
        }

        customAPI.get( 'staff/me', currentUser, (result) => {

            setUserIsLoaded(true);
            setCurrentUser(result);

        }, (error) => {
            setUserIsLoaded(true);
            setError(error);
        });

    }, [userIsLoaded, currentUser]);


    useEffect(() => {

        if (companyIsLoaded){
            return () => {};
        }

        if (!currentUser || !currentUser.hash){
            return () => {};
        }

        customAPI.get( 'companies/current', currentUser, (result) => {

            setCompanyIsLoaded(true);

            document.title = result.name + ' System';
            
            setCurrentCompany(result);

            setMenuItems(result.menu);

        }, (error) => {
            setCompanyIsLoaded(true);
            setError(error);
        });        
        
    }, [companyIsLoaded, currentUser]);




    /**
     * Open the menu when having mobile screen
     */
    const openMenu = () => {
        setIsMenuOpened((prevState) => {
            setIsMenuOpened(!prevState);
        });

        if (document.body) {
            if (isMenuOpened) {
                document.body.classList.remove('sidebar-enable');
            } else {
                document.body.classList.add('sidebar-enable');
            }
        }
    };
    

    const updateDimensions = useCallback(() => {
        // activate the condensed sidebar if smaller devices like ipad or tablet
        if (window.innerWidth >= 768 && window.innerWidth <= 1028) {
            dispatch(changeSidebarType(layoutConstants.LEFT_SIDEBAR_TYPE_CONDENSED));
        } else if (window.innerWidth > 1028) {
            dispatch(changeSidebarType(layoutConstants.LEFT_SIDEBAR_TYPE_FIXED));
        }
    }, [dispatch]);
    

    useEffect(() => {
        window.addEventListener('resize', updateDimensions);
        return () => {
            window.removeEventListener('resize', updateDimensions);
        };
    }, [dispatch, updateDimensions]);

    const isCondensed = leftSideBarType === layoutConstants.LEFT_SIDEBAR_TYPE_CONDENSED;
    const isLight = leftSideBarTheme === layoutConstants.LEFT_SIDEBAR_THEME_LIGHT;


    // console.info('Render...', error, userIsLoaded, currentUser);


    if (error) {
        return (
            <>
                <ErrorScreen error={error} />
            </>
        );
    } else if (!userIsLoaded && !companyIsLoaded) {
        return (
            <>
                <Loader />
            </>
        );
    } else if (!currentUser || !currentUser.hash){
        return (
            <Navigate to="account/login" />
        );
    } else {
        return (
            <>
                    <div className="wrapper">

                        {currentCompany ? (
                            <>
                            <style>
                                {currentCompany.custom_css}
                            </style>
                            </>
                        ) : (
                            <></>
                        )}

                        <Suspense fallback={loading()}>
                            <LeftSidebar isCondensed={isCondensed} isLight={isLight} hideUserProfile={true} menuItems={menuItems}  />
                        </Suspense>

                        <div className="content-page">
                            <div className="content">
                                
                                <Suspense fallback={loading()}>
                                    <Topbar openLeftMenuCallBack={openMenu} hideLogo={true} />
                                </Suspense>

                                <Container className="container-xl">
                                    <Outlet  />
                                </Container>
                                
                            </div>

                            <Suspense fallback={loading()}>
                                <Footer />
                            </Suspense>
                        </div>

                    </div>

                    {/* <Suspense fallback={loading()}>
                        <RightSidebar>
                            <ThemeCustomizer />
                        </RightSidebar>
                    </Suspense> */}
                
            </>
        );
    }
};

export default VerticalLayout;
