
import { createContext, useState } from 'react';

export const currentCompanyContext = createContext();

const CurrentCompanyProvider = (props) => {

    const [currentCompany, setCurrentCompany] = useState();

    return (
        <currentCompanyContext.Provider value={[currentCompany, setCurrentCompany]}>
            {props.children}
        </currentCompanyContext.Provider>
    );
}

export default CurrentCompanyProvider;
