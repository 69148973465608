
import { createContext, useState } from 'react';

export const currentUserContext = createContext();

export const hasPermission = ( permissions, permission ) => {
    
    if (!permissions){
        return false;
    }

    for (let p = 0;p < permissions.length; p++){
        if (permissions[p].urlslug === permission){
            if (permissions[p].granted === 1){
                return true;
            } else {
                return false;
            }
        }
    }

    return false;
}


const CurrentUserProvider = (props) => {

    const [currentUser, setCurrentUser] = useState({
        role: {
            permissions: []
        }
    });

    return (
        <currentUserContext.Provider value={ [currentUser, setCurrentUser] }>
            {props.children}
        </currentUserContext.Provider>
    );
}

export default CurrentUserProvider;