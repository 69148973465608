
import React, { Suspense } from 'react';

import { useRoutes } from 'react-router-dom';
// import config from '../config.js';
import { customAPI } from '../helpers-iacf/api';

import { useSelector } from 'react-redux';
import PrivateRoute from '../routes-iacf/PrivateRoute'; 
 
import Root from '../routes/Root';
// import * as layoutConstants from '../constants/layout';

// All layouts/containers
import DefaultLayout from './../layouts-iacf/Default';
import VerticalLayout from './../layouts-iacf/Vertical';
import PrintLayout from './../layouts-iacf/Print';

// auth
const Login = React.lazy(() => import('../pages-iacf/account/Login'));
const Logout = React.lazy(() => import('../pages-iacf/account/Logout'));

// dashboard
const DefaultDashboard = React.lazy(() => import('../pages-iacf/dashboard'));

const HelpDashboard = React.lazy(() => import('../pages-iacf/help/help-overview.js'));
const HelpArticle = React.lazy(() => import('../pages-iacf/help/help-article.js'));

const BookingsDashboard = React.lazy(() => import('../pages-iacf/bookings/bookings-overview.js'));

    const BookingsAdd = React.lazy(() => import('../pages-iacf/bookings/bookings-add.js'));
    // const BookingsMerge = React.lazy(() => import('../pages-iacf/bookings/bookings-merge.js'));
    // const BookingsUpdate = React.lazy(() => import('../pages-iacf/bookings/bookings-update.js'));
    const BookingsView = React.lazy(() => import('../pages-iacf/bookings/bookings-view.js'));
    // const BookingsAdd = React.lazy(() => import('../pages-iacf/bookings/bookings-add.js'));
    const BookingsReports = React.lazy(() => import('../pages-iacf/bookings/BookingReports.js'));
    const BatchPayment = React.lazy(() => import('../pages-iacf/bookings/BatchPaymentScreen.js'));

const UsersOverview = React.lazy(() => import('../pages-iacf/siteusers/siteusers-overview.js'));

    const UsersMerge = React.lazy(() => import('../pages-iacf/siteusers/siteusers-merge.js'));
    const UsersUpdate = React.lazy(() => import('../pages-iacf/siteusers/siteusers-update.js'));
    const UsersView = React.lazy(() => import('../pages-iacf/siteusers/siteusers-view.js'));
    const UsersAdd = React.lazy(() => import('../pages-iacf/siteusers/siteusers-add.js'));

const AddressesOverview = React.lazy(() => import('../pages-iacf/siteusers/addresses-overview.js'));  

const AddressesAdd = React.lazy(() => import('../pages-iacf/addresses/addresses-add.js'));



const EventsDashboard = React.lazy(() => import('../pages-iacf/events/events-overview.js'));

    // const EventsMerge = React.lazy(() => import('../pages-iacf/events/events-merge.js'));
    const EventsUpdate = React.lazy(() => import('../pages-iacf/events/events-update.js'));
    // const EventsView = React.lazy(() => import('../pages-iacf/events/events-view.js'));
    const EventsAdd = React.lazy(() => import('../pages-iacf/events/events-add.js'));
    const EventViewPage = React.lazy(() => import('../pages-iacf/events/events-view.js'));

const SiteplansDashboard = React.lazy(() => import('../pages-iacf/siteplans/siteplans-overview'));

    const SiteplansAdd = React.lazy(() => import('../pages-iacf/siteplans/siteplans-add.js'));
    const SiteplansUpdate = React.lazy(() => import('../pages-iacf/siteplans/siteplans-update.js'));
    const SiteplansView = React.lazy(() => import('../pages-iacf/siteplans/siteplans-view.js'));

const ZonesDashboard = React.lazy(() => import('../pages-iacf/zones/zones-overview.js'));

    const ZonesView = React.lazy(() => import('../pages-iacf/zones/zones-view.js'));
    const ZonesUpdate = React.lazy(() => import('../pages-iacf/zones/zones-update.js'));
    const ZonesAdd = React.lazy(() => import('../pages-iacf/zones/zones-add.js'));

const PitchesDashboard = React.lazy(() => import('../pages-iacf/pitches/pitches-overview.js'));

    // const PitchesView = React.lazy(() => import('../pages-iacf/pitches/pitches-view.js'));
    const PitchesUpdate = React.lazy(() => import('../pages-iacf/pitches/pitches-update.js'));
    const PitchesAdd = React.lazy(() => import('../pages-iacf/pitches/pitches-add.js'));    
    const PitchSearch = React.lazy(() => import('../pages-iacf/pitches/pitch-search.js'));    

const LocationsDashboard = React.lazy(() => import('../pages-iacf/locations/locations-overview'));

    // const LocationsMerge = React.lazy(() => import('../pages-iacf/locations/locations-merge.js'));
    const LocationsUpdate = React.lazy(() => import('../pages-iacf/locations/locations-update.js'));
    // const LocationsView = React.lazy(() => import('../pages-iacf/locations/locations-view.js'));
    const LocationsAdd = React.lazy(() => import('../pages-iacf/locations/locations-add.js'));

// const ExtrasDashboard = React.lazy(() => import('../pages-iacf/permits-and-extras/extras-overview'));
     
    const ExtrasUpdate = React.lazy(() => import('../pages-iacf/permits-and-extras/extras-update.js'));

const LogsDashboard = React.lazy(() => import('../pages-iacf/logs/logs-overview.js'));

    const LogsViewPage = React.lazy(() => import('../pages-iacf/logs/logs-view.js'));

const SettingsDashboard = React.lazy(() => import('../pages-iacf/settings/settings-overview.js'));

    const ListsOverview = React.lazy(() => import('../pages-iacf/settings/lists/lists-overview.js'));
    const ListsUpdate = React.lazy(() => import('../pages-iacf/settings/lists/lists-update.js'));
    const ListsAdd = React.lazy(() => import('../pages-iacf/settings/lists/lists-add.js'));

    const OrganisationOverview = React.lazy(() => import('../pages-iacf/settings/organisation/organisation-overview.js'));
    const OrganisationUpdate = React.lazy(() => import('../pages-iacf/settings/organisation/organisation-update.js'));

    const PermissionsOverview = React.lazy(() => import('../pages-iacf/settings/permissions/permissions-overview.js'));
    const PermissionsUpdate = React.lazy(() => import('../pages-iacf/settings/permissions/permissions-update.js'));
    
const StaffOverview = React.lazy(() => import('../pages-iacf/settings/staff/staff-overview.js'));

    const StaffUpdate = React.lazy(() => import('../pages-iacf/settings/staff/staff-update.js'));
    const StaffView = React.lazy(() => import('../pages-iacf/settings/staff/staff-view.js'));
    const StaffAdd = React.lazy(() => import('../pages-iacf/settings/staff/staff-add.js'));

    // import PaymentsAddPage from '../pages-iacf/settings/payments/payments-add';
    // import PaymentsUpdatePage from '../pages-iacf/settings/payments/payments-update';

    const PaymentsSettings = React.lazy(() => import('../pages-iacf/settings/payments/company-payment-methods.js'));

    // const PaymentsSettings = React.lazy(() => import('../pages-iacf/settings/payments/payments-overview'));
    const PaymentsAddPage = React.lazy(() => import('../pages-iacf/settings/payments/payments-add'));
    const PaymentsUpdatePage = React.lazy(() => import('../pages-iacf/settings/payments/payments-update'));
    const TaxesOverview = React.lazy(() => import('../pages-iacf/settings/taxes/taxes-overview'));

    const TaxesUpdate = React.lazy(() => import('../pages-iacf/settings/taxes/taxes-update'));

    const TerminologyOverview = React.lazy(() => import('../pages-iacf/settings/terminology/terminology-overview'));
    const TerminologyUpdate = React.lazy(() => import('../pages-iacf/settings/terminology/terminology-update'));

    const UserServicesOverview = React.lazy(() => import('../pages-iacf/settings/user-services/user-services-overview.js'));
    const UserServicesAddPage = React.lazy(() => import('../pages-iacf/settings/user-services/user-services-add.js'));
    const UserServicesUpdatePage = React.lazy(() => import('../pages-iacf/settings/user-services/user-services-update.js'));


    const ImportExportSettings = React.lazy(() => import('../pages-iacf/settings/import-export'));
    const ImportSiteusers = React.lazy(() => import('../pages-iacf/settings/import-export/import-siteusers.js'));
    const ImportOrders = React.lazy(() => import('../pages-iacf/settings/import-export/import-orders.js'));

    const ScanSettings = React.lazy(() => import('../pages-iacf/settings/scan.js'));

    const ScheduledTasksOverview = React.lazy(() => import('../pages-iacf/settings/scheduledtasks/ScheduledTasksOverview.js'));
        const ScheduledTaskAdd = React.lazy(() => import('../pages-iacf/settings/scheduledtasks/ScheduledTaskAdd.js'));
        const ScheduledTaskUpdate = React.lazy(() => import('../pages-iacf/settings/scheduledtasks/ScheduledTaskUpdate.js'));



const PrintOptionsPage = React.lazy(() => import('../printable/PrintOptionsPage.js'));

const PrintableTableLabels = React.lazy(() => import('../printable/PrintableTableLabels.js'));

const PrintableTicket = React.lazy(() => import('../printable/PrintableTicket.js'));

const PrintableTickets = React.lazy(() => import('../printable/PrintableTickets.js'));





// pages

const Profile = React.lazy(() => import('../pages-iacf/profile/profile-update.js'));

// const Profile2 = React.lazy(() => import('../pages/profile2'));
// const ErrorPageNotFound = React.lazy(() => import('../pages/error/PageNotFound'));
const PageNotFound = React.lazy(() => import('../pages-iacf/error/PageNotFound.js'));


// const ErrorPageNotFoundAlt = React.lazy(() => import('../pages/error/PageNotFoundAlt'));
const ServerError = React.lazy(() => import('../layouts-iacf/ErrorScreen.js'));

// - other
const MaintenanceScreen = React.lazy(() => import('../pages-iacf/Maintenance'));



const loading = () => <div className=""></div>;






const LoadComponent = ({ component: Component, layout }) => (
    <Suspense fallback={loading()}>
        <Component />
    </Suspense>
);



const AllRoutes = () => {


    const { layout } = useSelector((state) => ({
        layout: state.Layout,
    }));


    const getLayout = () => {

        let layoutCls = VerticalLayout;

        switch (layout.layoutType) {
            
            case 'print':
                layoutCls = PrintLayout;
                break;
                
            default:
                layoutCls = VerticalLayout;
                break;

        }
        return layoutCls;
    };


    let Layout = getLayout();

    return useRoutes([

        { 
            path: '/', 
            element: <Root /> 
        },

        {
            // public routes
            path: '/',
            element: <DefaultLayout />,
            children: [
                {
                    path: 'account',
                    children: [
                        { path: 'login', element: <LoadComponent component={Login} /> },
                        // { path: 'forget-password', element: <LoadComponent component={ForgetPassword} /> },
                        { path: 'logout', element: <LoadComponent component={Logout} /> }
                    ],
                },
                {
                    path: 'error-404',
                    element: <LoadComponent component={PageNotFound} />,
                },
                {
                    path: 'error-500',
                    element: <LoadComponent component={ServerError} />,
                },
                {
                    path: 'maintenance',
                    element: <LoadComponent component={MaintenanceScreen} />,
                }
            ],
        },

        {
            // auth protected routes
            path: '/',
            element: <PrivateRoute roles={'Admin'} component={Layout} />,
            children: [



                // Overall dashboard
                {
                    path: 'dashboard',
                    element: <LoadComponent component={DefaultDashboard}  />,
                },




                // Help
                {
                    path: 'help/:id',
                    element: <LoadComponent component={HelpArticle}  />,
                },                  
                {
                    path: 'help',
                    element: <LoadComponent component={HelpDashboard}  />,
                },                



                // Bookings
                // BookingsReports
                {
                    path: 'bookings/batch-payment',
                    element: <LoadComponent component={BatchPayment}  />
                },
                {
                    path: 'bookings/reports',
                    element: <LoadComponent component={BookingsReports}  />
                },
                {
                    path: 'bookings/:id',
                    element: <LoadComponent component={BookingsView}  />
                },
                {
                    path: 'bookings/add',
                    element: <LoadComponent component={BookingsAdd}  />
                },
                {
                    path: 'bookings',
                    element: <LoadComponent component={BookingsDashboard}  />
                },



                // Users (Stallholders)
                {
                    path: 'siteusers/:id/addresses/add',
                    element: <LoadComponent component={AddressesAdd} />
                },
                {
                    path: 'siteusers/:id/addresses',
                    element: <LoadComponent component={AddressesOverview} />
                },
                {
                    path: 'siteusers/:id/update',
                    element: <LoadComponent component={UsersUpdate} />
                },
                {
                    path: 'siteusers/:id/merge',
                    element: <LoadComponent component={UsersMerge}  />
                },                
                {
                    path: 'siteusers/:id',
                    element: <LoadComponent component={UsersView} />
                },
                {
                    path: 'siteusers/add',
                    element: <LoadComponent component={UsersAdd}  />
                },
                {
                    path: 'siteusers',
                    element: <LoadComponent component={UsersOverview}  />
                },



                // Events (Markets)
                {
                    path: 'events/pitch-search',
                    element: <LoadComponent component={PitchSearch}  />
                },
                {
                    path: 'events/:eventid/update',
                    element: <LoadComponent component={EventsUpdate}  />
                },
                {
                    path: 'events/:eventid',
                    element: <LoadComponent component={EventViewPage}  />
                },
                {
                    path: 'events/add',
                    element: <LoadComponent component={EventsAdd}  />
                },
                {
                    path: 'events',
                    element: <LoadComponent component={EventsDashboard}  />
                },                




                // Siteplans
                {
                    path: 'siteplans/:id/zones/:zoneid/pitches/add',
                    element: <LoadComponent component={PitchesAdd}  />
                },
                {
                    path: 'siteplans/:id/zones/:zoneid/pitches/:pitchid/update',
                    element: <LoadComponent component={PitchesUpdate}  />
                },
                {
                    path: 'siteplans/:id/pitches/:pitchid/update',
                    element: <LoadComponent component={PitchesUpdate}  />
                },

                // Note, we can get to the pitches via the siteplan, or 
                // filtered by a zone.
                {
                    path: 'siteplans/:id/zones/:zoneid/pitches',
                    element: <LoadComponent component={PitchesDashboard}  />
                },                      
                {
                    path: 'siteplans/:id/pitches/',
                    element: <LoadComponent component={PitchesDashboard}  />
                },
                // {
                //     path: 'siteplans/:id/pitches/add',
                //     element: <LoadComponent component={PitchesAdd}  />
                // },


                {
                    path: 'siteplans/:id/zones/:zoneid/update',
                    element: <LoadComponent component={ZonesUpdate}  />
                },
                {
                    path: 'siteplans/:id/zones/:zoneid',
                    element: <LoadComponent component={ZonesView}  />
                },                  
                {
                    path: 'siteplans/:id/zones',
                    element: <LoadComponent component={ZonesDashboard}  />
                },
                {
                    path: 'siteplans/:id/zones/add',
                    element: <LoadComponent component={ZonesAdd}  />
                },

                // {
                //     path: 'siteplans/:id/delete',
                //     element: <LoadComponent component={SiteplansUpdate}  />
                // },
                {
                    path: 'siteplans/:id/update',
                    element: <LoadComponent component={SiteplansUpdate}  />
                },
                {
                    path: 'siteplans/:id',
                    element: <LoadComponent component={SiteplansView}  />
                },
                {
                    path: 'siteplans/add',
                    element: <LoadComponent component={SiteplansAdd}  />
                },
                {
                    path: 'siteplans',
                    element: <LoadComponent component={SiteplansDashboard}  />
                },


                {
                    path: 'locations/:locationid/siteplans',
                    element: <LoadComponent component={SiteplansDashboard}  />
                },




                // Locations
                // {
                //     path: 'locations/:locationid/delete',
                //     element: <LoadComponent component={LocationsUpdate}  />
                // },
                {
                    path: 'locations/:locationid/update',
                    element: <LoadComponent component={LocationsUpdate}  />
                },
                // {
                //     path: 'locations/:locationid',
                //     element: <LoadComponent component={LocationsView}  />
                // },
                {
                    path: 'locations/add',
                    element: <LoadComponent component={LocationsAdd}  />
                },                
                {
                    path: 'locations',
                    element: <LoadComponent component={LocationsDashboard}  />
                },





                {
                    path: 'locations/:locationid/extras',
                    element: <LoadComponent component={ExtrasUpdate}  />
                },



                // Settings
                // {
                //     path: 'settings',
                //     children: [
                        {
                            path: 'settings/organisation/update',
                            element: <LoadComponent component={OrganisationUpdate}  />
                        },
                        {
                            path: 'settings/organisation',
                            element: <LoadComponent component={OrganisationOverview}  />
                        },


                        {
                            path: 'settings/permissions/update',
                            element: <LoadComponent component={PermissionsUpdate}  />
                        },
                        {
                            path: 'settings/permissions',
                            element: <LoadComponent component={PermissionsOverview}  />
                        },


                        {
                            path: 'settings/staff/:id',
                            element: <LoadComponent component={StaffView} />
                        },
                        {
                            path: 'settings/staff/:id/update',
                            element: <LoadComponent component={StaffUpdate} />
                        },
                        {
                            path: 'settings/staff/add',
                            element: <LoadComponent component={StaffAdd}  />
                        },
                        {
                            path: 'settings/staff',
                            element: <LoadComponent component={StaffOverview}  />
                        },


                        {
                            path: 'settings/payments', 
                            element: <LoadComponent component={PaymentsSettings}  />
                        },
                        {
                            path: 'settings/payments/add', 
                            element: <LoadComponent component={PaymentsAddPage}  />
                        },
                        {
                            path: 'settings/payments/:id/update', 
                            element: <LoadComponent component={PaymentsUpdatePage}  />
                        },

                        {
                            path: 'settings/taxes/update',
                            element: <LoadComponent component={TaxesUpdate}  />
                        },                        
                        {
                            path: 'settings/taxes',
                            element: <LoadComponent component={TaxesOverview}  />
                        },


                        {
                            path: 'settings/terminology/update',
                            element: <LoadComponent component={TerminologyUpdate}  />
                        },
                        {
                            path: 'settings/terminology',
                            element: <LoadComponent component={TerminologyOverview}  />
                        },


                       
                        {   
                            path: 'settings/lists/:id/update',
                            element: <LoadComponent component={ListsUpdate} />
                        },
                        {   
                            path: 'settings/lists/add',
                            element: <LoadComponent component={ListsAdd} />
                        },
                        {   
                            path: 'settings/lists',
                            element: <LoadComponent component={ListsOverview} />
                        },



                        {
                            path: 'settings/logs/:id',
                            element: <LoadComponent component={LogsViewPage}  /> 
                        },                        

                        {
                            path: 'settings/logs',
                            element: <LoadComponent component={LogsDashboard}  /> 
                        },



                        {
                            path: 'settings/user-services/:id/update',
                            element: <LoadComponent component={UserServicesUpdatePage}  />
                        },
                        {
                            path: 'settings/user-services/add',
                            element: <LoadComponent component={UserServicesAddPage}  />
                        },
                        {
                            path: 'settings/user-services',
                            element: <LoadComponent component={UserServicesOverview}  />
                        },


                        {
                            path: 'settings/import-export/import-orders',
                            element: <LoadComponent component={ImportOrders}  />
                        },
                        {
                            path: 'settings/import-export/import-siteusers',
                            element: <LoadComponent component={ImportSiteusers}  />
                        },
                        {
                            path: 'settings/import-export',
                            element: <LoadComponent component={ImportExportSettings}  />
                        },




                        {
                            path: 'settings/scheduled-tasks/:id/update',
                            element: <LoadComponent component={ScheduledTaskUpdate}  />
                        },
                        {
                            path: 'settings/scheduled-tasks/add',
                            element: <LoadComponent component={ScheduledTaskAdd}  />
                        },
                        {
                            path: 'settings/scheduled-tasks',
                            element: <LoadComponent component={ScheduledTasksOverview}  />
                        },                        


                        {
                            path: 'settings/scan',
                            element: <LoadComponent component={ScanSettings}  />
                        },

                        
                //     ]
                // },

                {
                    path: 'settings',
                    element: <LoadComponent component={SettingsDashboard} />
                },



                // Staff/profile
                {
                    path: 'profile',
                    element: <LoadComponent component={Profile} />,
                },



                // Print
                {
                    path: 'print',
                    element: <LoadComponent component={PrintOptionsPage}  />,
                },



                // Logout
                { 
                    path: 'logout', 
                    element: <LoadComponent component={Logout} /> 
                },
               


                // Catchall for 404 page:
                {
                    path: '*',
                    element: <LoadComponent component={PageNotFound} />
                }
            ],
        },

        {
            // auth protected routes
            path: '/print',
            element: <PrivateRoute roles={'Admin'} component={PrintLayout} />,
            children: [

                {
                    path: 'events/:id/table-labels',
                    element: <LoadComponent component={PrintableTableLabels}  />,
                },
                {
                    path: 'bookings/:id/ticket',
                    element: <LoadComponent component={PrintableTicket}  />,
                },
                {
                    path: 'events/:id/tickets',
                    element: <LoadComponent component={PrintableTickets}  />,
                },
                {
                    path: 'tickets',
                    element: <LoadComponent component={PrintableTickets}  />,
                }                 
            ]
        }

    ]);

};

export { AllRoutes };
